import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import SelectSearch from 'react-select-search';
// import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import axiosInstance from '../../../utils/axiosInstance';
import customToast from '../../../utils/ToastifyHelper';
import * as actionCreators from '../../../store/actions/exportAction';
import { formatIntTwoDigitString } from '../../../utils/GeneralHelper';
import TimePicker from 'react-time-picker';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { format } from 'date-fns';
import { showError } from '../../../utils/ErrorHelper';


const typeAbsences = [
    { 'codeAbsence': 'AEF', 'libelleAbsence': 'Absence évènement familiale' },
    { 'codeAbsence': 'AT', 'libelleAbsence': 'Accident de travail' },
    { 'codeAbsence': 'ABST', 'libelleAbsence': 'Autre absence' },
    { 'codeAbsence': 'PATER', 'libelleAbsence': 'Congés paternité' },
    { 'codeAbsence': 'CP', 'libelleAbsence': 'Congés payés' },
    { 'codeAbsence': 'CSS', 'libelleAbsence': 'Congés sans solde' },
    { 'codeAbsence': 'COURS', 'libelleAbsence': 'École' },
    { 'codeAbsence': 'FO', 'libelleAbsence': 'Formation' },
    { 'codeAbsence': 'MAL', 'libelleAbsence': 'Maladie' },
    { 'codeAbsence': 'MALP', 'libelleAbsence': 'Maladie professionnelle' },
    { 'codeAbsence': 'MATER', 'libelleAbsence': 'Maternité' },
];

const onModifyAbsence = (absenceUpdated, oldAbsenceId) => (actionCreators.modifyAbsence(absenceUpdated, oldAbsenceId));

const ModifyAbsenceForm = (props) => {
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [formErrorDate, setFormErrorDate] = useState(null);
    const [formErrorSalarie, setFormErrorSalarie] = useState(null);
    const [salarieSelectedId, setSalarieSelectedId] = useState(props.absence.utilisateur_absent._id);
    const dispatch = useDispatch();

    const userListState = useSelector(state => state.userList);

    const [tempsAbsence, setTempsAbsence] = useState(
        props.absence.tempsAbsence.heure === null ? null : formatIntTwoDigitString(props.absence.tempsAbsence.heure) + ":" + formatIntTwoDigitString(props.absence.tempsAbsence.minute)
    );

    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    const onSubmit = data => {
        setFormErrorDate(false);
        // verifier si absence existe déjà
        setButtonDisabled(true);
        if (salarieSelectedId === null) {
            setFormErrorSalarie(true);
        }
        if (valueDateAbsence === null) {
            setFormErrorDate(true);
        }
        if ((formErrorDate === false || valueDateAbsence !== null) && (formErrorSalarie === false || salarieSelectedId !== null)) {
            let heure, minute = null;
            if (tempsAbsence !== null) {
                const temps = tempsAbsence.match(/^(\d+):(\d+)/);
                if (temps !== null) {
                    heure = parseInt(temps[1]);
                    minute = parseInt(temps[2]);
                }
            }
            let postData = {
                utilisateur_absent: salarieSelectedId,
                commentaire: data.commentaire,
                type_absence: data.TypeAbsence,
                heure: heure,
                minute: minute
            };
            if (valueDateAbsence[0] !== null) {
                postData['date_deb_absence'] = format(new Date(valueDateAbsence[0]), "yyyy-MM-dd");
                if (valueDateAbsence[1] !== null) {
                    postData['date_fin_absence'] = format(new Date(valueDateAbsence[1]), "yyyy-MM-dd");
                }
                else {
                    postData['date_fin_absence'] = format(new Date(valueDateAbsence[0]), "yyyy-MM-dd");
                }
            }
            else {
                postData['date_fin_absence'] = format(new Date(valueDateAbsence[1]), "yyyy-MM-dd");
                postData['date_deb_absence'] = format(new Date(valueDateAbsence[1]), "yyyy-MM-dd");
            }
            axiosInstance.patch(`/absences/${props.absence.utilisateur_absent._id}&${format(new Date(props.absence.date_deb_absence), "yyyy-MM-dd")}`, postData)
                .then((result) => {
                    customToast.success("L'abscence à été modifié avec succès");
                    dispatch(onModifyAbsence(result.data, { utilisateur_absent: props.absence.utilisateur_absent, date_deb_absence: props.absence.date_deb_absence }));
                    close();
                }).catch((err) => {
                    setButtonDisabled(false);
                    showError(err);
                });
        }
        else {
            setButtonDisabled(false);
        }
    };

    const schema = yup.object().shape({
        TypeAbsence: yup.string().required("Veuillez sélectionner un type d'absence"),
        commentaire: yup.string()
    });
    const { register, handleSubmit } = useForm({
        resolver: yupResolver(schema)
    });

    const searchInput = useRef();

    const ouvrierOptions = userListState.ouvrierList.map((ouvrier) => {
        let fullName = ouvrier._id.nom + " " + ouvrier._id.prenom;
        return { name: fullName, value: ouvrier._id._id };
    });
    const chefChantierOptions = userListState.chefChantierList.map((chef) => {
        let fullName = chef._id.nom + " " + chef._id.prenom;
        return { name: fullName, value: chef._id._id };
    });
    const interimaireOptions = userListState.interimaireList.map((interimaire) => {
        let fullName = interimaire._id.nom + " " + interimaire._id.prenom;
        return { name: fullName, value: interimaire._id._id };
    });

    const options = [
        {
            type: 'group',
            name: "Ouvrier",
            items: ouvrierOptions
        },
        {
            type: 'group',
            name: "Chef de chantier",
            items: chefChantierOptions
        },
        {
            type: 'group',
            name: "Intérimaire",
            items: interimaireOptions
        }
    ];

    const handleChange = (...args) => {
        // console.log("ARGS:", args);
        // console.log("CHANGE:");
        setFormErrorSalarie(false);
        setSalarieSelectedId(args[0]);
    };

    const handleFilter = (items) => {
        return (searchValue) => {
            if (searchValue.length === 0) {
                return options;
            }
            const updatedItems = items.map((list) => {
                const newItems = list.items.filter((item) => {
                    return item.name.toLowerCase().includes(searchValue.toLowerCase());
                });
                return { ...list, items: newItems };
            });
            return updatedItems;
        };
    };

    const [valueDateAbsence, onChange] = useState([new Date(props.absence.date_deb_absence), props.absence.date_fin_absence === null ? null : new Date(props.absence.date_fin_absence)]);

    return (
        <div className="modal-content modifier-form">
            <h3 className="modal-title">Modifier une absence</h3>
            <button className='modal-button' onClick={() => close()}>x</button>
            <form className="vertical-form scrollable" onSubmit={handleSubmit(onSubmit)}>
                <label className='default-label' htmlFor="salarie">Choisir un salarié</label>
                <SelectSearch
                    ref={searchInput}
                    options={options}
                    filterOptions={handleFilter}
                    value={salarieSelectedId}
                    name="salarie"
                    placeholder="Choisir un salarié"
                    search
                    onChange={handleChange}
                />
                {formErrorSalarie && <p className="login-error-message">Veuillez sélectionner un salarié</p>}
                <label className='ajouter-form-label default-label' htmlFor="typeAbsence">Choisir un type d'absence</label>
                <select className='new-default-input default-select' name="typeAbsence" defaultValue={props.absence.type_absence} {...register("TypeAbsence", { required: true })}>
                    {typeAbsences.map((type) => {
                        return (
                            <option key={type.codeAbsence} value={type.libelleAbsence}>{type.libelleAbsence}</option>
                        );
                    })}
                </select>
                <label className='ajouter-form-label default-label' htmlFor="debutAbsence">Durée de l'absence</label>
                <DateRangePicker required={true} minDate={new Date("2022-01-01")} maxDate={new Date("2099-12-31")} className="input-datetimePicker" showLeadingZeros={true} format="dd/MM/yyyy" onChange={onChange} value={valueDateAbsence} />
                {formErrorDate && <p className="login-error-message">Veuillez sélectionner une date de début et de fin d'absence</p>}
                <label className='ajouter-form-label default-label' >Temps d'absence</label>
                <TimePicker showLeadingZeros={true} className="input-datetimePicker" disableClock onChange={setTempsAbsence} format="HH:mm" value={tempsAbsence} />
                <label className='ajouter-form-label default-label' htmlFor="commentaireAbsence">Commentaire</label>
                <input className='default-input' type="text" placeholder="Commentaire" defaultValue={props.absence.commentaire} {...register("commentaire", {})} />
                <div className="button-container">
                    <button disabled={isButtonDisabled} className='default-button modifier-form-annuler' onClick={() => close()}>Annuler</button>
                    <input disabled={isButtonDisabled} className="default-button modifier-form-valider" type="submit" value="modifier" />
                </div>
            </form>
        </div>
    );
};

export default ModifyAbsenceForm;
